import React from 'react';
import { Link } from 'react-router-dom';
 
class Welcome extends React.Component {
    render(){
        return (
            <section id="welcome" className="welcome-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h4>Hola</h4>
                        <h2>Bienvenido al perfil profesional de <span>Nestor Fock</span></h2>
                        <p>Este sitio recoge información publica importante de mi carta de vida profesional, contáctame para que juntos podamos crear soluciones innovadoras a los retos tecnológicos de la nueva realidad</p>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Welcome;