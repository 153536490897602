import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Particles from 'react-particles-js';
import ReactTypingEffect from 'react-typing-effect';

const particleOpt = {
    "particles": {
      "number": {
        "value": 80,
        "density": {
          "enable": true,
          "value_area": 1000
        }
      },
      "opacity": {
        "value": 0.5,
        "random": false,
        "anim": {
          "enable": false,
          "speed": 1,
          "opacity_min": 0.1,
          "sync": false
        }
      },
      "size": {
        "value": 3
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "repulse"
        },
        "onclick": {
          "enable": true,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "repulse": {
          "distance": 200,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 4
        }
      }
    }
  }
  
 
class MainBanner extends React.Component {
    render(){
        return (
            <div id="home" className="main-banner item-bg-one">
                <div className="creative-banner-one"></div>
                <Particles
                    params={{...particleOpt}}
                />
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-banner-text">
                                        <h1> 
                                            <ReactTypingEffect
                                                text={[  'Ingeniero.', 'Emprendedor.', 'Líder.', 'Scrum Master.','Desarrollador de Software.', 'Project Manager.', 'Profesional.', 'Creativo.']}
                                                speed={100}
                                                eraseDelay={50}
                                            />
                                        </h1>
                                        <h4>Nestor Fock</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;