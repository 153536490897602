import React from 'react';
import MainBanner from '../PageBanners/MainBannerEleven';
import Welcome from '../Common/Welcome';
import Skill from '../Common/Skill';
import Funfact from '../Common/Funfact';
import Work from '../Common/Work';
import Partner from '../Common/Partner';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
 
class HomeEleven extends React.Component {
    render(){
        return (
            <React.Fragment>
                {/* Main Banner */}
                <MainBanner />
                {/* Welcome Area */}
                <Welcome />
                {/* CTR Area */}
                <Skill />
                {/* Fact Area */}
                <Funfact />
                {/* Team Area */}
                <Work />
                {/* Price Area */}
                <Partner />
                {/* Subscribe Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default HomeEleven;