import React from 'react';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import mixitup from "mixitup";

const images = [
    require("../../assets/images/work-img1.jpg"),
    require("../../assets/images/work-img2.png"),
    require("../../assets/images/work-img2-2.png"),
    require("../../assets/images/work-img2-3.png"),
    require("../../assets/images/work-img3-1.png"),
    require("../../assets/images/work-img3-2.png"),
    require("../../assets/images/work-img3.png"),
    require("../../assets/images/work-img4-1.png"),
    require("../../assets/images/work-img4-2.png"),
    require("../../assets/images/work-img4-3.png"),
    require("../../assets/images/work-img4-4.png"),
    require("../../assets/images/work-img4-5.png")
];
 
class Work extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false
    };

    componentDidMount() {
        mixitup("#mix-wrapper", {
            animation: {
                effects: "fade rotateZ(0deg)",
                duration: 700
            },
            classNames: {
                block: "programs",
                elementFilter: "filter-btn",
            },
            selectors: {
                target: ".mix-target"
            }
        });
    }

    render(){
        const { photoIndex, isOpen } = this.state;
        return (
            <section id="work" className="work-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Educación</span></h2>
                        <p>Soy autodidacta y mi curiosidad por aprender me ha llevado a explorar muchos cursos presenciales y digitales, actualmente estudio el último trimestre de la Maestría en Administración de Negocios en la Universidad Mariano Gálvez de Guatemala.</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="shorting-menu">
                                <button 
                                    className="filter" 
                                    data-filter="all"
                                >
                                    Todas
                                </button>
                                <button 
                                    className="filter" 
                                    data-filter=".u"
                                >
                                    Universidad
                                </button>
                                
                                <button 
                                    className="filter"
                                    data-filter=".scrum"
                                >
                                    ProjectManager/Scrum
                                </button>
                                <button 
                                    className="filter" 
                                    data-filter=".dataScience"
                                >
                                    DataScience
                                </button>
                                <button 
                                    className="filter" 
                                    data-filter=".tec"
                                >
                                    Tecnología y otros
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="shorting">
                    <div className="row m-0" id="mix-wrapper">
                        <div 
                            className="col-lg-4 col-md-6 mix u p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[0]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Ingeniero en Sistemas de Información y Ciencias de la Computación</h4>
                                    <ul>
                                        <li><Link to="#">UMG</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 0, isOpen: true })}
                                ><i className="fa fa-plus"></i></Link>
                            </div>
                        </div>
                        
                        
                        
                        <div className="col-lg-4 col-md-6 mix tec p-0 mix-target">
                            <div className="single-work">
                                <img src={images[4]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Análisis y Control Estadístico de Procesos con R/Rstudio</h4>
                                    <ul>
                                        <li><Link to="#">UVG</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 1, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 mix dataScience p-0 mix-target">
                            <div className="single-work">
                                <img src={images[5]} alt="work-img" />
                                <div className="work-content">
                                    <h4>Python para Data Science</h4>
                                    <ul>
                                        <li><Link to="#">UVG</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 2, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[1]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Scrum Master Certified</h4>
                                    <ul>
                                        <li><Link to="#">ScrumStudy</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[2]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Scrum Fundamentals Certified</h4>
                                    <ul>
                                        <li><Link to="#">ScrumStudy</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[3]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Scrum Fundation Profesional Certificate (SFPC)</h4>
                                    <ul>
                                        <li><Link to="#">CertiProf</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[6]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Certificación Profesional Administración del Transporte y Distribución</h4>
                                    <ul>
                                        <li><Link to="#">Panamerican Business School</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 6, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[7]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>SCRUM Master Certified: Gestión Ágil de Proyectos</h4>
                                    <ul>
                                        <li><Link to="#">Panamerican Business School</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 7, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[8]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Administración y Dirección de Proyectos</h4>
                                    <ul>
                                        <li><Link to="#">KINAL</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 8, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix tec p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[9]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Preparation for Certified Information Systems Auditor</h4>
                                    <ul>
                                        <li><Link to="#">SISAP</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 9, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[10]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Dirección Profesional de Proyectos Basados en PMBOK</h4>
                                    <ul>
                                        <li><Link to="#">ITLearning New Horizons</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 10, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div 
                            className="col-lg-4 col-md-6 mix scrum p-0 mix-target"
                        >
                            <div className="single-work">
                                <img src={images[11]} alt="work-img" />
                                
                                <div className="work-content">
                                    <h4>Course of Project Managment Professional PMP</h4>
                                    <ul>
                                        <li><Link to="#">ITLearning New Horizons</Link></li>
                                    </ul>
                                </div>
                                
                                <Link 
                                    to="#" 
                                    className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 11, isOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length
                        })
                        }
                    />
                )}
            </section>
        );
    }
}
 
export default Work;