import React from 'react';
import { Link } from 'react-router-dom';
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-1">
                            <img style={{ maxWidth: 150 }} src={require("../../assets/images/logo.png")}/>
                            <p><br/></p>
                            <ul>
                                <li><a href="https://www.facebook.com/njfock" target="_blank" className="fa fa-facebook"></a></li>
                                <li><a href="https://twitter.com/NJFock" target="_blank"  className="fa fa-twitter"></a></li>
                                <li><a href="https://github.com/njfock" target="_blank"  className="fa fa-github"></a></li>
                                <li><a href="https://www.linkedin.com/in/nestor-josé-fock-aguirre-ab7b3962/" target="_blank"  className="fa fa-linkedin"></a></li>
                            </ul>
                            <p>Copyright <i className="fa fa-copyright"></i> Nexthor 2020 All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;